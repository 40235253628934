import React from 'react';
import './Privacy.css';

function Privacy(
    {en = 'Chemistry Explorer', zh = '化学探索', email = 'suggestpaqe@163.com'}
) {
    const browserLanguage = navigator.language;
    const useChinese = browserLanguage.startsWith('zh');
    // console.log(browserLanguage);
    return (
        <div style={{
            margin: '30px'
        }}>
            {!useChinese ? (<>
                    <h1>Privacy Policy</h1>
                    <p>This Privacy Policy is intended to clearly inform users of [{en}] how we handle and protect your
                        personal information when you use our services. We are committed to respecting your privacy and
                        protecting your personal information securely.</p>

                    <h2>1. Information Collection and Use</h2>
                    <p>Our app does not actively collect any personal identification information from users. The only
                        instance
                        where we store personal information is if you voluntarily provide feedback or suggestions via email,
                        in
                        which case we may retain your email address to respond to inquiries, feedback, or provide necessary
                        user
                        support.</p>

                    <h2>2. Information Storage and Protection</h2>
                    <p>Personal information provided by users through email will be stored securely by us and protected with
                        appropriate security measures to prevent unauthorized access, disclosure, alteration, or
                        destruction.</p>

                    <h2>3. Information Sharing and Disclosure</h2>
                    <p>We will not sell, trade, or rent users' personal information to third parties. We may disclose
                        personal
                        information to comply with legal requirements or for public safety purposes, only in these specific
                        circumstances will we disclose users' personal information.</p>

                    <h2>4. User Rights</h2>
                    <p>Users can request to view, modify, or delete their personal information at any time by sending an
                        email
                        to our official email address. Upon receiving the request, we will take appropriate action as soon
                        as
                        possible and notify the user.</p>

                    <h2>5. Changes to the Privacy Policy</h2>
                    <p>We reserve the right to update or modify this Privacy Policy at any time. Such changes will be posted
                        on
                        our app or official website, and continuing to use our services after these changes are posted will
                        be
                        considered as acceptance of those changes.</p>

                    <h2>Contact Us</h2>
                    <p>If you have any questions or concerns about our Privacy Policy, please contact us at the following
                        email
                        address: [{email}].</p>
                </>)
                : <>

                    <h1>隐私政策</h1>
                    <p>本隐私政策旨在明确地向{zh}的用户说明，在使用我们的服务时，我们如何处理和保护用户的个人信息。我们承诺尊重用户的隐私并且保护用户的个人信息安全。</p>

                    <h2>1. 信息收集与使用</h2>
                    <p>我们的App不会主动收集用户的任何个人识别信息。用户在使用过程中，除非主动通过电子邮件向我们提供反馈或建议，否则我们不会存储任何个人信息。</p>
                    <p>当用户决定通过电子邮件与我们联系时，我们可能会留存用户的电子邮件地址以便回复咨询、反馈或提供必要的用户支持。这是我们收集信息的唯一情形。</p>

                    <h2>2. 信息的存储与保护</h2>
                    <p>用户通过电子邮件提供的个人信息，将被我们谨慎地存储并采取相应的安全措施保护，以防止未经授权的访问、披露、修改或销毁。</p>

                    <h2>3. 信息的共享与披露</h2>
                    <p>我们不会将用户的个人信息出售、交易或出租给第三方。我们可能会因应法律要求或出于公共安全的目的，需要披露必要的个人信息，仅在这些特定情况下，我们才会披露用户的个人信息。</p>

                    <h2>4. 用户权利</h2>
                    <p>用户可以随时通过发送电子邮件到我们的官方邮箱，要求查看、修改或删除其个人信息。我们将在收到请求后，尽快采取相应措施，并通知用户。</p>

                    <h2>5. 隐私政策的变更</h2>
                    <p>我们保留随时更新或修改本隐私政策的权利。任何此类更改将会在我们的App或官方网站上公布，并且更改生效后，继续使用我们的服务将视为用户接受了这些更改。</p>

                    <h2>联系我们</h2>
                    <p>如果您对我们的隐私政策有任何疑问或关注，请通过以下电子邮件地址与我们联系：[{email}]。</p>
                </>}
        </div>
    );
}

export default Privacy;
