import React from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Privacy from "./Privacy";
import Visualize from "./Visualize";

const App = () => {
    return <BrowserRouter>
        <Routes>
            <Route path="/" element={Privacy({})}/>
            <Route path="/privacy" element={Privacy({})}/>
            <Route path="/visual" element={Visualize()}/>
        </Routes>
    </BrowserRouter>
};

export default App;
