import React, {useEffect, useRef, useState} from 'react';
import {createViewer} from '3dmol';

const Visualize = () => {
    const viewerRef = useRef();
    const [sdfData, setSdfData] = useState('');

    // 假设query参数是通过URL的search参数传递
    const query = new URLSearchParams(window.location.search);

    const smiles = query.get('smiles');
    const forceField = query.get('forceField');
    const initAutoRotate = query.get('autoRotate') === '1';
    const initModelType = query.get('modelType') || 'stick';

    const viewerInstanceRef = useRef(null);
    const [viewerCreated, setViewCreated] = useState(false);

    const [autoRotate, setAutoRotate] = useState(initAutoRotate);
    const [modelType, setModelType] = useState(initModelType);

    // const [data, setData] = useState('No data received from Flutter');

    function setViewStyle(modelType) {
        const viewer = viewerInstanceRef.current;
        switch (modelType) {
            case 'ballAndStick':
                viewer.setStyle({}, {
                    stick: {},
                    sphere: {radius: 0.5},
                });
                break;
            case 'cpk':
                viewer.setStyle({}, {
                    // colorscheme: "Jmol",
                    sphere: {}
                });
                break;
            case 'stick':
                viewer.setStyle({}, {
                    stick: {
                        // radius: 0.1,
                    }
                });
                break;
            case 'cross':
                viewer.setStyle({}, {
                    cross: {
                        // radius: 0.1,
                    }
                });
                break;
            case 'wireframe':
                viewer.setStyle({}, {

                    line: {
                        // radius: 0.1,
                    }
                });
                break;
            // case 'cartoon':
            //     viewer.setStyle({}, {
            //         cartoon: {
            //             color: 'spectrum',
            //         }
            //     });
            //     break;
            default:
                viewer.setStyle({}, {
                    stick: {
                        // radius: 0.1,
                    }
                });
        }
        viewer.render();
    }

    useEffect(() => {
        // 根据query参数请求SDF数据
        const fetchSdfData = async () => {
            try {
                const response = await fetch(`/api/visualize/3d?smiles=${smiles}&forceField=${forceField}`);
                const sdf = await response.text();
                setSdfData(sdf);
            } catch (error) {
                console.error('Failed to fetch SDF data:', error);
            }
        };
        if (smiles && forceField) {
            fetchSdfData();
        }
    }, [smiles, forceField]);

    useEffect(() => {
        if (viewerRef.current && sdfData) {
            const viewer = createViewer(viewerRef.current, {
                backgroundColor: '0xFFD4E5F1',
            });
            viewerInstanceRef.current = viewer;
            viewer.resize(); // 调用resize方法来更新viewer的尺寸

            viewer.addModel(sdfData, "sdf");
            viewer.setClickable({}, true, function (atom, viewer, event, container) {
                if (!atom.label) {
                    atom.label = viewer.addLabel(atom.serial + ":" + atom.atom, {
                        position: atom,
                        backgroundColor: 'mintcream',
                        fontColor: 'black',
                        fontSize: 12,
                    });
                } else {
                    viewer.removeLabel(atom.label);
                    delete atom.label;
                }
                viewer.render();
            });
            viewer.zoomTo(); // 调整视角以适应分子

            // viewer.render();
            // viewer.rotate(0, 'x');
            // viewer.rotate(0, 'y');
            // viewer.rotate(0, 'z');

            setViewStyle(modelType);

            setViewCreated(true);
        }
        // 组件卸载时的清理工作
        return () => {
            if (viewerInstanceRef.current) {
                viewerInstanceRef.current = null;
            }
        };
    }, [sdfData]); // 当sdfData更新时重新渲染

    useEffect(() => {
        if (viewerCreated) {
            viewerInstanceRef.current.spin(autoRotate);
        }
    }, [autoRotate, viewerCreated]);

    useEffect(() => {
        if (viewerCreated) {
            // setData(modelType.toString());
            setViewStyle(modelType);
        }
    }, [modelType, viewerCreated]);

    useEffect(() => {
        // 定义全局方法
        window.receiveDataFromFlutter = (data) => {
            setAutoRotate(data["autoRotate"] === '1');
            setModelType(data["modelType"]);
        };
    }, []);

    useEffect(() => {
        const observer = new ResizeObserver((entries) => {
            for (let entry of entries) {
                const {width, height} = entry.contentRect;
                // 确保 viewerInstanceRef.current 不是 null
                console.log(width, height);
                if (viewerInstanceRef.current && width > 0 && height > 0) {
                    viewerInstanceRef.current.resize();
                }
            }
        });

        if (viewerRef.current) {
            observer.observe(viewerRef.current);
        }

        return () => {
            if (viewerRef.current) {
                observer.unobserve(viewerRef.current);
            }
        };
    }, []); // 空依赖数组意味着此 effect 只在组件挂载时运行一次

    return (
        <div style={{width: '100%', height: '100vw', position: 'relative'}}>
            <div ref={viewerRef} style={{width: '100%', height: '100%'}}></div>
        </div>
    );
};

export default Visualize;
